<template>
  <div class="wrapper">
    <div class="container-fluid" v-if="loading">
      <div class="row">
        <div class="col d-flex justify-content-center">
          <loader class="my-5" color="rgba(255, 152, 0, 0.75)"></loader>
        </div>
      </div>
    </div>
    <div class="container-fluid" v-if="!loading">
      <div class="row">
        <div class="col">
          <div class="card-deck">
            <div class="card card-shadow">
              <div class="card-body">
                <h1 class="card-title">
                  {{ shoppingLists.length }}
                </h1>
              </div>
              <div class="card-footer bg-dark text-light">
                Total Shopping Lists
              </div>
            </div>
            <div class="card card-shadow">
              <div class="card-body">
                <h1 class="card-title">
                  {{ pantryLists.length }}
                </h1>
              </div>
              <div class="card-footer bg-dark text-light">
                Total Pantry Lists
              </div>
            </div>
            <div class="card card-shadow">
              <div class="card-body">
                <h1 class="card-title">
                  {{ totalFamiliesShopping }}
                </h1>
              </div>
              <div class="card-footer bg-dark text-light">
                Families with Shopping Lists
              </div>
            </div>
            <div class="card card-shadow">
              <div class="card-body">
                <h1 class="card-title">
                  {{ totalFamiliesPantry }}
                </h1>
              </div>
              <div class="card-footer bg-dark text-light">
                Families with Pantry Lists
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="card card-shadow mt-3">
            <div class="container-fluid">
              <div class="row">
                <div class="col text-left">
                  <div class="card-body">
                    <b-form inline>
                      <b-form-input
                        size="sm"
                        v-model="searchText"
                        placeholder="Search..."
                      ></b-form-input>
                    </b-form>
                  </div>
                </div>
                <div class="col text-right">
                  <div class="card-body">
                    <b-button
                      variant="outline-success"
                      size="sm"
                      v-b-modal="'createList'"
                      ><i class="fas fa-plus"></i> Create List</b-button
                    >

                    <b-modal
                      id="createList"
                      title="Create List"
                      ok-title="Create"
                      ok-variant="primary"
                      @ok="create()"
                    >
                      <b-form-group label="Family">
                        <autocomplete
                          :search="searchFamilies"
                          :get-result-value="getFamilyValue"
                          overwrite-class="user"
                          placeholder="e.g. Smith Family"
                          @change="updateFamily"
                        ></autocomplete>
                      </b-form-group>
                      <b-form-group label="List Type">
                        <b-form-radio-group
                          v-model="list.type"
                          :options="['Shopping', 'Pantry']"
                        ></b-form-radio-group>
                      </b-form-group>
                      <b-form-group
                        description="A name for the new shopping list."
                        label="List Name"
                        label-for="shopping-list-name"
                      >
                        <b-form-input
                          id="shopping-list-name"
                          v-model="list.name"
                          trim
                        ></b-form-input>
                      </b-form-group>
                      <b-form-group
                        description="The planned shopping date for the list."
                        label="Shopping Date"
                        label-for="shopping-list-date"
                        v-if="list.type === 'Shopping'"
                      >
                        <b-form-datepicker
                          id="shopping-list-date"
                          v-model="list.date"
                        ></b-form-datepicker>
                      </b-form-group>
                    </b-modal>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <b-table
                    hover
                    striped
                    responsive
                    :fields="fields"
                    :items="filteredLists"
                  >
                    <template #cell(actions)="data">
                      <b-button
                        variant="outline-primary"
                        size="sm"
                        @click="edit(data.item)"
                        ><i class="fas fa-edit"></i
                      ></b-button>
                      <b-button
                        variant="outline-danger"
                        size="sm"
                        class="ml-1"
                        v-b-modal="'confirmDelete' + data.item.key"
                        ><i class="far fa-trash-alt"></i
                      ></b-button>

                      <b-modal
                        :id="'confirmDelete' + data.item.key"
                        title="Delete List"
                        ok-title="Delete"
                        ok-variant="danger"
                        @ok="deleteList(data.item.key)"
                      >
                        Are you sure you want to delete list
                        {{ data.item.name }}? This cannot be undone.
                      </b-modal>
                    </template>
                  </b-table>
                </div>
                <div class="col profile-tab" v-if="showEdit">
                  <b-form @submit.prevent="isNew ? create() : update()">
                    <b-tabs content-class="mt-3 mx-3" class="mb-5" fill>
                      <b-tab title="List" active>
                        <div class="form-wrapper">
                          <b-form-group
                            description="A name for the new shopping list."
                            label="List Name"
                            label-for="shopping-list-name"
                          >
                            <b-form-input
                              id="shopping-list-name"
                              v-model="list.name"
                              trim
                            ></b-form-input>
                          </b-form-group>
                          <b-form-group
                            description="The planned shopping date for the list."
                            label="Shopping Date"
                            label-for="shopping-list-date"
                            v-if="list.type === 'Shopping'"
                          >
                            <b-form-datepicker
                              id="shopping-list-date"
                              v-model="list.date"
                            ></b-form-datepicker>
                          </b-form-group>
                          <b-form-group v-if="list.type === 'Shopping'">
                            <b-form-checkbox
                              v-model="list.move_purchased"
                              class="pt-2 mb-2"
                              >Move purchased items to pantry
                              list</b-form-checkbox
                            >
                            <b-form-select
                              v-model="list.pantry_list"
                              :options="pantryListOptions"
                              value-field="key"
                              text-field="name"
                              placeholder="e.g. My pantry list"
                            ></b-form-select>
                          </b-form-group>
                        </div>
                      </b-tab>
                      <b-tab title="Items">
                        <div class="form-wrapper text-center">
                          <div class="autocomplete input-group mb-3 text-left">
                            <autocomplete
                              ref="autocomplete"
                              class="autocomplete-wrapper"
                              placeholder="e.g. Lean Ground Beef"
                              :search="searchFood"
                              :get-result-value="getFoodValue"
                              @change="updateFood"
                            ></autocomplete>
                            <div class="input-group-append">
                              <button
                                id="list-add-item"
                                class="btn btn-outline-success"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                title="Add Item"
                                @click="addItem"
                              >
                                <i class="fas fa-plus"></i>
                              </button>
                            </div>
                          </div>

                          <draggable
                            tag="div"
                            class="list-group"
                            handle=".handle"
                            :list="list.items"
                            @end="updateItems"
                          >
                            <b-list-group-item
                              class="list-item-row list-group-item text-left"
                              v-for="(item, index) in list.items"
                              :key="index"
                            >
                              <div>
                                <div class="handle list-handle">
                                  <i class="fas fa-grip-vertical"></i>
                                </div>
                                <div class="d-flex justify-content-between">
                                  <span
                                    @click="expandItem(index)"
                                    :class="
                                      item.purchased
                                        ? 'strikethrough cursor-pointer item-span'
                                        : 'cursor-pointer item-span'
                                    "
                                    >{{ item.food.name }}
                                    <small v-if="item.amount && item.amount > 0"
                                      ><i
                                        >{{ formatAmount(item.amount) }}
                                        {{ formatUnit(item.unit) }}</i
                                      ></small
                                    ></span
                                  >
                                  <div v-if="list.type === 'Shopping'">
                                    <b-form-checkbox
                                      v-model="item.purchased"
                                      @change="updateItems($event, index)"
                                    ></b-form-checkbox>
                                  </div>
                                  <div v-if="list.type === 'Pantry'">
                                    {{ formatPurchaseDate(item.purchase_date) }}
                                  </div>
                                  <div v-if="list.type === 'Pantry'">
                                    <b-button
                                      variant="outline-danger"
                                      class="ml-2"
                                      size="sm"
                                    >
                                      <i class="far fa-trash-alt"></i>
                                    </b-button>
                                  </div>
                                </div>
                              </div>
                              <div
                                v-if="itemsExpanded[index]"
                                class="d-flex w-100 justify-content-between py-3"
                              >
                                <div class="input-group pr-5">
                                  <input
                                    name="amount"
                                    type="text"
                                    class="form-control input-amount"
                                    placeholder="e.g. 1"
                                    v-model="item.amount"
                                  />
                                  <div class="input-group-append">
                                    <autocomplete
                                      :search="searchUnits"
                                      :get-result-value="getUnitValue"
                                      :default-value="formatUnit(item.unit)"
                                      :index="index"
                                      overwrite-class="list-unit"
                                      placeholder="e.g. Ounces (oz)"
                                      @change="updateUnit"
                                    ></autocomplete>
                                  </div>
                                </div>
                                <b-button
                                  variant="outline-danger"
                                  @click="removeItem(index)"
                                  v-if="list.type !== 'Pantry'"
                                >
                                  <i class="far fa-trash-alt"></i>
                                </b-button>
                              </div>
                              <div
                                v-if="itemsExpanded[index]"
                                class="d-flex w-100 justify-content-center"
                              >
                                <b-button
                                  variant="secondary"
                                  size="sm"
                                  class="px-5 py-0"
                                  @click="closeItem(index)"
                                >
                                  <i class="fas fa-angle-double-up"></i>
                                </b-button>
                              </div>
                            </b-list-group-item>
                          </draggable>
                        </div>
                      </b-tab>
                      <template #tabs-end>
                        <li
                          role="presentation"
                          class="nav-item align-self-center justify-content-end text-right"
                        >
                          <b-button
                            type="submit"
                            variant="outline-success"
                            :disabled="saving"
                            size="sm"
                            class="mr-1"
                          >
                            <span v-if="saving">
                              <b-spinner small></b-spinner>
                              <span class="sr-only">Saving...</span>
                            </span>
                            <span v-else>
                              <i class="far fa-save"></i>
                            </span>
                          </b-button>
                          <b-button
                            variant="outline-danger"
                            @click="closeEdit"
                            size="sm"
                            class="mr-1"
                            ><i class="far fa-window-close"></i
                          ></b-button>
                        </li>
                      </template>
                    </b-tabs>
                  </b-form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import FirebaseMixin from "@/mixins/Firebase";
import UtilsMixin from "@/mixins/Utils";
import Loader from "@/components/Loader";
import Autocomplete from "@/components/Autocomplete";
import draggable from "vuedraggable";
import Fraction from "fraction.js";
import { DateTime } from "luxon";

export default {
  name: "Lists",
  mixins: [FirebaseMixin, UtilsMixin],
  components: {
    loader: Loader,
    Autocomplete,
    draggable: draggable
  },
  data() {
    return {
      bind: [
        { ref: "families", target: "families" },
        { ref: "lists", target: "lists" },
        { ref: "food", target: "food" },
        { ref: "units", target: "units" }
      ],
      loading: false,
      saving: false,
      lists: [],
      families: [],
      listKey: null,
      list: { items: {} },
      newItem: {
        food: { name: "" },
        unit: { name: "", label: "" },
        purchased: false
      },
      users: [],
      food: [],
      units: {},
      isNew: false,
      fields: [
        {
          key: "key",
          label: "Key",
          sortable: false
        },
        {
          key: "name",
          label: "Name",
          sortable: true
        },
        {
          key: "family.name",
          label: "Family",
          sortable: true
        },
        {
          key: "type",
          label: "Type",
          sortable: true
        },
        {
          key: "actions",
          label: "Actions"
        }
      ],
      showEdit: false,
      itemsExpanded: [],
      searchText: "",
      days: [
        { text: "Sunday", value: "sunday" },
        { text: "Monday", value: "monday" },
        { text: "Tuesday", value: "tuesday" },
        { text: "Wednesday", value: "wednesday" },
        { text: "Thursday", value: "thursday" },
        { text: "Friday", value: "friday" },
        { text: "Saturday", value: "saturday" }
      ]
    };
  },
  computed: {
    ...mapState(["isAuthenticated", "userProfile", "config"]),
    ...mapGetters(["searchConfig"]),
    shoppingLists() {
      return this.lists.flatMap(list => {
        if ("shopping" in list) {
          const familyKey = list[".key"];
          return Object.keys(list["shopping"]).map(key => {
            let familyIndex = this.getIndex(this.families, familyKey);
            console.log(familyIndex);
            let family = familyIndex !== null ? this.families[familyIndex] : {};
            return {
              key: key,
              family_key: familyKey,
              family: family,
              ...list["shopping"][key]
            };
          });
        } else {
          return [];
        }
      });
    },
    pantryLists() {
      return this.lists.flatMap(list => {
        if ("pantry" in list) {
          const familyKey = list[".key"];
          return Object.keys(list["pantry"]).map(key => {
            let familyIndex = this.getIndex(this.families, familyKey);
            console.log(familyIndex);
            let family = familyIndex !== null ? this.families[familyIndex] : {};
            return {
              key: key,
              family_key: familyKey,
              family: family,
              ...list["pantry"][key]
            };
          });
        } else {
          return [];
        }
      });
    },
    allLists() {
      let lists = [];
      this.shoppingLists.forEach(list => {
        lists.push({
          type: "Shopping",
          ...list
        });
      });
      this.pantryLists.forEach(list => {
        lists.push({
          type: "Pantry",
          ...list
        });
      });
      return lists;
    },
    filteredLists() {
      if (this.searchText.length > 0) {
        const searchText = this.searchText.toLowerCase();
        return this.allLists.filter(list => {
          let showList = false;
          if (
            list.key.toLowerCase().includes(searchText) ||
            list.name.toLowerCase().includes(searchText) ||
            list.type.toLowerCase().includes(searchText) ||
            list.family.name.toLowerCase().includes(searchText)
          ) {
            showList = true;
          }
          if ("items" in list) {
            list.items.forEach(item => {
              if (
                "food" in item &&
                String(item.food.name)
                  .toLowerCase()
                  .includes(searchText)
              ) {
                showList = true;
              }
            });
          }
          return showList;
        });
      } else {
        return this.allLists;
      }
    },
    totalFamiliesShopping() {
      return Object.keys(this.lists).filter(familyKey => {
        if ("shopping" in this.lists[familyKey]) {
          return true;
        } else {
          return false;
        }
      }).length;
    },
    totalFamiliesPantry() {
      return Object.keys(this.lists).filter(familyKey => {
        if ("pantry" in this.lists[familyKey]) {
          return true;
        } else {
          return false;
        }
      }).length;
    },
    pantryListOptions() {
      return this.pantryLists.filter(list => {
        return (list.family_key = this.list.family_key);
      });
    },
    foodList() {
      let food;
      try {
        food = this.createUniqueList(this.food, this.familyFood);
      } catch (e) {
        console.log(e);
        food = [];
      }
      return food;
    },
    familyUnitSystem() {
      if ("family" in this.list && "unit_system" in this.list.family) {
        return this.list.family.unit_system;
      } else {
        return "imperial";
      }
    }
  },
  watch: {
    lists: {
      immediate: true,
      handler(lists) {
        lists.forEach((list, index) => {
          if (!Array.isArray(list.items)) {
            this.lists[index].items = [];
          }
        });
      }
    }
  },
  methods: {
    searchFamilies(input) {
      if (input.length < 1) {
        return [];
      }
      return this.families.filter(family => {
        return (
          family[".key"].toLowerCase().startsWith(input.toLowerCase()) ||
          family.name.toLowerCase().startsWith(input.toLowerCase())
        );
      });
    },
    formatFamily(family) {
      return family.name + " (" + family[".key"] + ")";
    },
    getFamilyValue(result) {
      return this.formatFamily(result);
    },
    updateFamily(value) {
      const family = { ...value };
      if (family) {
        this.list.family_key = value[".key"];
        this.list.family = {
          ...family
        };
      }
    },
    searchFood(input) {
      if (input.length < 1) {
        return [];
      }
      return this.foodList.filter(food => {
        return food.name.toLowerCase().startsWith(input.toLowerCase());
      });
    },
    getFoodValue(result) {
      return result.name;
    },
    updateFood(value) {
      const food = { ...value };
      this.newItem.food = food;
    },
    searchUnits(input) {
      if (input.length < 1) {
        return [];
      }
      return this.units[this.familyUnitSystem].filter(unit => {
        return (
          unit.name.toLowerCase().startsWith(input.toLowerCase()) ||
          unit.label.toLowerCase().startsWith(input.toLowerCase())
        );
      });
    },
    formatUnit(unit) {
      let formatted = "";
      if ("name" in unit && "label" in unit) {
        formatted = unit.name + " (" + unit.label + ")";
      }
      console.log(formatted);
      return formatted.replace(" ()", "");
    },
    getUnitValue(result) {
      return this.formatUnit(result);
    },
    updateUnit(value, index) {
      const unit = { ...value };
      console.log("Unit", unit, "Index", index);
      if ("name" in unit) {
        const item = { ...this.list.items[index], unit: unit };
        this.$set(this.list.items, index, item);
      }
    },
    edit(list) {
      this.isNew = false;
      this.itemsExpanded = [];
      if (!Array.isArray(list.items)) {
        list.items = [];
      }
      this.listKey = list.key;
      this.list = { ...list, items: [...list.items] };
      this.showEdit = true;
    },
    closeEdit() {
      this.list = { items: [] };
      this.showEdit = false;
    },
    newList() {
      this.isNew = true;
      this.list = { items: [] };
      this.showEdit = true;
    },
    create() {
      this.saving = true;
      let list = { ...this.list };
      const type = this.list.type.toLowerCase();
      delete list.key;
      delete list.family_key;
      delete list.family;
      this.createObject("lists/" + this.list.family_key + "/" + type, list)
        .then(() => {
          this.saving = false;
          window.toastr.success("Family created successfully.");
        })
        .catch(error => {
          this.saving = false;
          window.toastr.error(
            "An error occurred creating the family: " + error
          );
          console.error("Unable to create family", error);
        });
      return true;
    },
    update() {
      this.saving = true;
      const list = { ...this.list };
      const type = this.list.type.toLowerCase();
      delete list.key;
      delete list.family_key;
      delete list.family;
      if (this.listKey && this.listKey.length > 0) {
        this.updateObject(
          "lists/" + this.list.family_key + "/" + type,
          this.listKey,
          list
        )
          .then(() => {
            this.saving = false;
            window.toastr.success("List updated successfully.");
          })
          .catch(error => {
            this.saving = false;
            window.toastr.error(
              "An error occurred updating the list: " + error
            );
            console.error("Unable to update list", this.listKey, error);
          });
      }
      return true;
    },
    deleteList(key) {
      this.saving = true;
      const list = this.allLists[this.getIndex(this.allLists, key, "key")];
      const type = list.type.toLowerCase();
      this.deleteObject("lists/" + list.family_key + "/" + type, key)
        .then(() => {
          this.saving = false;
          window.toastr.success("List deleted successfully.");
        })
        .catch(error => {
          this.saving = false;
          window.toastr.error("An error occurred deleting the list: " + error);
          console.error("Unable to delete list", key, error);
        });
      return key;
    },
    addItem() {
      this.list.items.push({
        food: { ...this.newItem.food },
        unit: { ...this.newItem.unit },
        purchased: this.newItem.purchased
      });
      this.newItem = {
        food: { name: "" },
        unit: { name: "", label: "" },
        purchased: false
      };
      this.list = { ...this.list, items: [...this.list.items] };
      this.$forceUpdate();
    },
    removeItem(index) {
      this.list.items.splice(index, 1);
      this.$forceUpdate();
    },
    expandItem(index) {
      this.closeAllItems();
      this.$set(this.itemsExpanded, index, true);
    },
    closeItem(index) {
      this.$set(this.itemsExpanded, index, false);
    },
    closeAllItems() {
      this.list.items.forEach((item, index) => {
        this.$set(this.itemsExpanded, index, false);
      });
    },
    updateItems(value = null, index = null) {
      this.closeAllItems();
      if (value !== null && index !== null) {
        this.list.items[index].purchased = value;
      }
    },
    formatAmount(amount) {
      if (this.userNumberFormat === "fractions") {
        const f = new Fraction(amount);
        amount = f.toFraction(true);
      }
      return amount;
    },
    formatPurchaseDate(date) {
      let purchased = DateTime.fromMillis(date);
      return purchased.toLocaleString();
    }
  },
  created() {}
};
</script>

<style>
.wrapper {
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
}

.user-input {
  height: calc(1.5em + 0.75rem + 2px);
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.user-input:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.user-result-list {
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  list-style-type: none;
  cursor: pointer;
  z-index: 999;
}

.user-result-list:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.autocomplete-wrapper {
  width: calc(100% - 40px);
}

.autocomplete-input {
  width: 100%;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.list-unit {
  max-width: 185px;
  border-radius: 0 !important;
}

.list-unit-input {
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0 !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  max-width: 185px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.list-unit-input:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.list-unit-result-list {
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  max-width: 185px;
  list-style-type: none;
  cursor: pointer;
}

.list-unit-result-list:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
</style>

<style scoped>
.card-shadow {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -4px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.background-none {
  background: none;
}

.profile-tab {
  max-width: 600px;
}

.list-handle {
  width: 30px;
  height: 28px;
  float: left;
  cursor: pointer;
}

.item-span {
  width: calc(100% - 24px);
}

.input-amount {
  max-width: 180px;
}

.strikethrough {
  text-decoration: line-through;
}

.cursor-pointer {
  cursor: pointer;
}
</style>
